<template>
    <div class="create_new_check_list">
        <div class="create_new_check_list-header">
            <h2>צ'ק ליסט ניהול</h2>
        </div>
        <div class="create_new_check_list-content-tools">
            <el-button  @click="state='CreateNewCheckList'" type="success">חדש</el-button>
            <el-button style="margin-right:5px;" @click="state='ChecklistsInformations'" type="warning">ניהול</el-button>
            <el-button @click="state='CheckListsReports'" type="primary">דיווחים</el-button>
        </div>
        <div class="create_new_check_list-content">
            <keep-alive>
                <component :is="state" />
            </keep-alive>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import CreateNewCheckList from './CreateNewCheckList.vue'
import ChecklistsInformations from './ChecklistsInformations.vue'
import CheckListsReports from './Check_lists_reports/CheckListsReports.vue'
export default {
    components:{CreateNewCheckList,ChecklistsInformations,CheckListsReports},
    setup(){
        const state = ref('CreateNewCheckList')
        return{state}
    }
}
</script>

<style scoped>
    .create_new_check_list{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        color: #fff;
        padding: 5px;
    }
    .create_new_check_list-header{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--secondary);
        border-radius: 5px;
    }
    .create_new_check_list-content{
        width: 100%;
        height: calc(100% - 105px);
        overflow:hidden;
        overflow-y: auto;
    }
    .create_new_check_list-content-tools{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 5px;
        background: var(--secondary);
        margin-top: 5px;
        border-radius: 5px;
    }

    :global(.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered, .el-radio.is-bordered+.el-radio.is-bordered){
        margin-left: 5px;
    }
</style>