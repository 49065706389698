import { computed } from "vue";
import { ref } from "vue";
import { projectFirestore, projectFunctions } from "../../../../../firebase/config";
import store from '../../../../../store'

const add_new_check_list_in_db = async(data)=>{
    const doc = projectFirestore.collection('Applications').doc('de5uIwg712BvvI4EHBzH')
    .collection('Check_lists').doc()
    data.uid = doc.id
    await doc.set(data)
}

const get_all_check_lists_form_db = async() => {
    const docs = await projectFirestore.collection('Applications').doc('de5uIwg712BvvI4EHBzH')
    .collection('Check_lists').get()
    const data = docs.docs.map(doc=>doc.data())
    return data
}

const delete_specific_check_list_db = async(list) => {
    await projectFirestore.collection('Applications').doc('de5uIwg712BvvI4EHBzH')
    .collection('Check_lists').doc(list.uid).delete()
}

const edit_specific_check_list_db = async(list) => {
    delete list.created_at
    await projectFirestore.collection('Applications').doc('de5uIwg712BvvI4EHBzH')
    .collection('Check_lists').doc(list.uid).set(list,{merge:true})
}

const get_submits_check_list = async (days,roles)=>{
    let res
    if(days){
        res = await projectFunctions.httpsCallable('get_all_submit_check_lists')({days,roles})
    }else{
        res = await projectFunctions.httpsCallable('get_all_submit_check_lists')({roles})
    }
    return res.data
}

const delete_submit_from_db = async(sub) =>{
    await projectFirestore.collection('Applications').doc('de5uIwg712BvvI4EHBzH')
    .collection('Check_lists').doc(sub.uid_belong_to).collection('Submits').doc(sub.check_list_uid)
    .delete()
}

const platform = ref(computed(()=>{
    return store.state.platform
}))

export{
    add_new_check_list_in_db,
    get_all_check_lists_form_db,
    delete_specific_check_list_db,
    edit_specific_check_list_db,
    get_submits_check_list,
    delete_submit_from_db,
    platform,
}